<template>
    <div class="body fixed mobile">
        <!-- class: fixed, mobile -->
        <!-- Loading Spinner -->
        <!-- <div class="spinner"><div><div></div></div></div> -->
        <!-- Header -->
        <section class="header news">
            <a
                class="icon iconButton third"
                @click.prevent="$router.push('/home')"
            >
                <font-awesome-icon
                    icon="fa-solid fa-chevron-left"
                    size="lg"
                ></font-awesome-icon>
            </a>
            <span class="fL nowrap">最新消息</span>
            <div class="icon iconButton"></div>
        </section>
        <!-- Main -->
        <section class="main news">
            <!-- Filter -->
            <div class="filter flexH width">
                <div class="flexH width middle">
                    <div class="dropdown rounded">
                        <select name="type" class="fM rounded">
                            <option value="全部通知">全部分類</option>
                        </select>
                        <div class="arrow third">
                            <font-awesome-icon
                                icon="fas fa-caret-down"
                                size="xs"
                            ></font-awesome-icon>
                        </div>
                    </div>
                </div>
                <!-- <div class="lineV"></div>
                    <div class="flexH width middle">
                        <div class="dropdown rounded">
                            <select name="type" class="fM rounded">
                                <option value="新到舊">新到舊</option>
                                <option value="舊到新">舊到新</option>
                            </select>
                            <div class="arrow third"><i class="fas fa-caret-down fa-xs"></i></div>
                        </div>
                    </div> -->
            </div>
            <!-- List -->
            <div class="list flexV width padding">
                <a
                    class="news flexH width margin between"
                    v-for="(article, index) in articles"
                    :key="'article' + index"
                    @click.prevent="toArticle(article.id)"
                >
                    <div class="flexV padding margin height between">
                        <span class="fM title">{{ article.title }}</span>
                        <span class="fS">{{ article.departmentName }}</span>
                        <span
                            class="fS double"
                            v-html="article.htmlContent"
                        ></span>
                        <div class="flexH">
                            <span class="fXS gray" v-if="article.startTs">{{
                                tsToData(article.startTs)
                            }}</span>
                            <span class="fXS gray">・</span>
                            <span class="fXS gray">{{
                                article.categoryName
                            }}</span>
                        </div>
                    </div>
                    <div class="img"><img :src="article.imageUrl" /></div>
                </a>
            </div>
        </section>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
    name: "",
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            articles: "article/getArticles"
        }),
    },
    methods: {
        tsToData(ts) {
            if (!ts) return '';
            return moment.unix(ts).utcOffset(8).format('YYYY-MM-DD');
        },
        toArticle(articleId) {
            this.$router.push({path: `/articles/${articleId}`});
        }
    }
};
</script>
